import React from 'react';
import { Box, CssBaseline, Typography, Paper, Fade } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavigationBar from './NavigationBar';
import './PrivacyPolicy.css'; // 같은 스타일 사용

const theme = createTheme();

const Terms = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <NavigationBar />
        <CssBaseline />
        <div className="privacy-policy-container gradient-background">
          <div className="privacy-policy-content">
            <Typography variant="h3" component="h1" className="privacy-policy-title">
              크루크루(crewcrew) 서비스 이용약관
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              시행 일자: 2024년 08월 01일
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제1조 (목적)
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              본 약관은 크루크루(이하 "회사")가 제공하는 엑셀 스케줄-구글 캘린더 연동 서비스(이하 "서비스")의 이용조건 및 절차, 회사와 회원의 권리·의무 관계를 규정함을 목적으로 합니다.
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제2조 (정의)
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            </Typography>
            
            <ol className="privacy-policy-list">
              <li>서비스: 회사가 제공하는 승무원 스케줄 관리 및 일정 동기화 서비스</li>
              <li>회원: 카카오톡, 구글, 애플 계정으로 로그인하여 본 서비스를 이용하는 자</li>
              <li>엑셀 파일: 회원이 업로드하는 일정 데이터가 포함된 파일</li>
              <li>구글 캘린더: Google LLC에서 제공하는 일정 관리 서비스</li>
              <li>소셜 로그인: 카카오톡, 구글, 애플 계정을 통한 로그인 서비스</li>
            </ol>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제3조 (서비스 이용)
            </Typography>
            
            <ol className="privacy-policy-list">
              <li>서비스 이용을 위해서는 카카오톡, 구글, 또는 애플 계정을 통한 로그인이 필수적입니다.</li>
              <li>일정 동기화 기능 사용을 위해서는 구글 캘린더 접근 권한을 허용해야 합니다.</li>
              <li>회사는 업로드된 엑셀 파일의 데이터를 구글 캘린더에 동기화하는 서비스만을 제공합니다.</li>
              <li>회원은 올바른 형식의 엑셀 파일을 업로드해야 하며, 잘못된 형식으로 인한 오류는 회원의 책임입니다.</li>
              <li>회원은 서비스 이용을 위해 개인정보 수집 및 이용에 동의해야 합니다.</li>
            </ol>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제4조 (서비스의 제한)
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회원은 다음 각 호의 행위를 하여서는 안됩니다:
            </Typography>
            
            <ol className="privacy-policy-list">
              <li>타인의 소셜 계정 무단 사용</li>
              <li>악성코드가 포함된 파일 업로드</li>
              <li>서비스의 정상적인 운영을 방해하는 행위</li>
              <li>다른 회원의 정보를 무단으로 수집, 저장, 공개하는 행위</li>
              <li>회사의 서비스를 이용하여 영리 목적의 활동을 하는 행위</li>
              <li>기타 관련 법령에 위배되는 행위</li>
            </ol>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제5조 (서비스 중단)
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 다음 각 호의 경우 서비스 제공을 중단할 수 있습니다:
            </Typography>
            
            <ol className="privacy-policy-list">
              <li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
              <li>Google의 정책 변경 등으로 인한 서비스 제공이 불가능한 경우</li>
              <li>천재지변, 정전, 서비스 설비의 장애 등으로 정상적인 서비스 제공이 불가능한 경우</li>
              <li>기타 불가항력적 사유가 있는 경우</li>
            </ol>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제6조 (책임제한)
            </Typography>
            
            <ol className="privacy-policy-list">
              <li>회사는 회원이 업로드한 엑셀 파일의 내용에 대해 책임지지 않습니다.</li>
              <li>회사는 Google, Kakao, Apple 등 외부 서비스의 변경, 중단으로 인한 손해에 대해 책임지지 않습니다.</li>
              <li>회사는 회원의 귀책사유로 인한 서비스 이용 장애에 대해 책임지지 않습니다.</li>
              <li>회원이 업로드한 데이터의 백업은 회원의 책임입니다.</li>
            </ol>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제7조 (저작권)
            </Typography>
            
            <ol className="privacy-policy-list">
              <li>서비스 내의 모든 저작권과 기타 지적재산권은 회사에 귀속됩니다.</li>
              <li>회원은 업로드한 엑셀 파일에 대한 저작권 침해가 없음을 보증합니다.</li>
            </ol>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제8조 (개인정보보호)
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하며, 자세한 내용은 개인정보처리방침에서 정한 바에 따릅니다.
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제9조 (약관의 개정)
            </Typography>
            
            <ol className="privacy-policy-list">
              <li>회사는 필요한 경우 본 약관을 개정할 수 있습니다.</li>
              <li>약관 개정 시 회사는 개정된 약관을 서비스 내에 공지합니다.</li>
              <li>회원이 개정된 약관에 동의하지 않는 경우 서비스 이용을 중단할 수 있습니다.</li>
            </ol>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              제10조 (준거법 및 관할법원)
            </Typography>
            
            <ol className="privacy-policy-list">
              <li>본 약관은 대한민국 법률에 따라 규율되고 해석됩니다.</li>
              <li>서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속적 관할법원으로 합니다.</li>
            </ol>

            <Typography variant="body1" className="privacy-policy-text">
              부칙
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              본 약관은 2024년 08월 01일부터 시행합니다.
            </Typography>
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default Terms;