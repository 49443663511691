import React, { useState } from 'react';
import { Calendar, Upload, Clock, Shield, Globe, Lock, Users, AlertCircle } from 'lucide-react';
import NavigationBar from './components/NavigationBar';
import './LandingPage.css';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const LandingPage = () => {
    const navigate = useNavigate();
    const [showComingSoon, setShowComingSoon] = useState(false);

    const handleLoginClick = () => {
        navigate('/login');
    };

    const handleAppStoreClick = () => {
        window.location.href = 'https://apps.apple.com/kr/app/crewcrew/id6739111418';
    };

    const handlePlayStoreClick = () => {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.sunmo.crewcrew&pcampaignid=web_share';
    };

    return (
        <div className="landing-container">
            <NavigationBar />
            
            {/* Hero Section */}
            <section className="hero-section">
                <div className="hero-content">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="hero-text"
                    >
                        <h1 className="hero-title">
                            더 편리한 스케줄 관리를 시작하세요
                        </h1>
                        <p className="hero-subtitle">
                            항공사 승무원들을 위한 스마트한 스케줄 관리와 소통 플랫폼
                        </p>
                        <div className="hero-buttons">
                            {/* <button className="google-login-button" onClick={handleLoginClick}>
                                <div className="google-login-button-inner">
                                    <GoogleIcon />
                                    <span className="google-button-text">
                                        Google로 시작하기
                                    </span>
                                </div>
                            </button> */}
                            <button className="app-download-button" onClick={handleAppStoreClick}>
                                <div className="app-download-button-inner">
                                    <svg className="app-store-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M17.05 20.28c-.98.95-2.05.8-3.08.35-1.09-.46-2.09-.48-3.24 0-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8 1.18-.24 2.31-.93 3.57-.84 1.51.12 2.65.72 3.4 1.8-3.12 1.87-2.38 5.98.48 7.13-.57 1.5-1.31 2.99-2.53 4.08M13 5.13c-.76.83-2.22 1.48-3.58 1.34-.19-1.6.73-3.27 2.24-4.08C12.49 1.64 14 1.33 15.25 1c.19 1.62-.73 3.26-2.25 4.13"/>
                                    </svg>
                                    <span>App Store</span>
                                </div>
                            </button>
                            <button className="app-download-button" onClick={handlePlayStoreClick}>
                                <div className="app-download-button-inner">
                                    <svg className="play-store-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M3.609 1.814L13.792 12L3.61 22.186C3.385 21.988 3.25 21.702 3.25 21.377V2.623C3.25 2.298 3.385 2.013 3.609 1.814M17.01 12L20.375 15.365C20.745 15.736 20.745 16.265 20.375 16.635L6.512 22.374L17.01 12M6.51 1.623L20.374 7.363C20.744 7.734 20.744 8.263 20.374 8.633L17.01 12L6.51 1.623M5.01 2.123V21.877L15.333 12L5.01 2.123Z"/>
                                    </svg>
                                    <span>Google Play</span>
                                </div>
                            </button>
                        </div>
                    </motion.div>
                    <div className="app-preview">
                        <div className="screenshots-row">
                            {[1, 2, 3, 4, 5].map((num) => (
                                <motion.div
                                    key={num}
                                    className="screenshot-item"
                                    whileHover={{ scale: 1.05 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    <img src={`/${num}.png`} alt={`CrewCrew App ${num}`} />
                                </motion.div>
                            ))}
                        </div>
                        <div className="screenshots-row">
                            {[6, 7, 8, 9, 10].map((num) => (
                                <motion.div
                                    key={num}
                                    className="screenshot-item"
                                    whileHover={{ scale: 1.05 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    <img src={`/${num}.png`} alt={`CrewCrew App ${num}`} />
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Coming Soon Alert */}
            {showComingSoon && (
                <motion.div 
                    className="coming-soon-alert"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                >
                    <AlertCircle size={20} />
                    <span>CrewCrew 앱이 곧 출시됩니다!</span>
                </motion.div>
            )}

            {/* Features Section */}
            <section className="features-section">
                <div className="features-content">
                    <h2 className="section-title">주요 기능</h2>
                    <p className="section-subtitle">
                        CrewCrew는 항공사 승무원들의 스케줄 관리와 소통을 더 쉽고 편리하게 만들어주는 앱입니다
                    </p>
                    <div className="features-grid">
                        <FeatureCard
                            icon={<Calendar />}
                            title="스마트 스케줄 관리"
                            description="회사 스케줄 자동 파싱, 구글 캘린더 연동, 실시간 업데이트로 더 편리한 일정 관리"
                        />
                        <FeatureCard
                            icon={<Users />}
                            title="크루 소통"
                            description="실시간 스케줄 공유, 같은 레이오버 크루 찾기, 카카오톡 친구 목록 연동으로 쉬운 소통"
                        />
                        <FeatureCard
                            icon={<Globe />}
                            title="레이오버 정보"
                            description="도시 정보 공유, 함께하는 크루 찾기, 실시간 스케줄 비교 기능 제공"
                        />
                    </div>
                </div>
            </section>

            {/* OAuth Permissions Section */}
            <section className="oauth-section">
                <div className="oauth-content">
                    <h2 className="section-title">
                        Google Calendar 권한 안내
                    </h2>
                    <div className="oauth-grid">
                        <OAuthCard
                            icon={<Calendar />}
                            title="캘린더 읽기/쓰기"
                            description="스케줄을 구글 캘린더에 자동으로 동기화하기 위해 필요합니다"
                        />
                        <OAuthCard
                            icon={<Lock />}
                            title="기본 프로필 정보"
                            description="서비스 이용을 위한 계정 식별용으로만 사용됩니다"
                        />
                    </div>
                </div>
            </section>

            {/* Security Section */}
            <section className="security-section">
                <div className="security-content">
                    <h2 className="section-title">보안 및 개인정보</h2>
                    <div className="security-grid">
                        <SecurityCard
                            icon={<Shield />}
                            title="데이터 암호화"
                            description="모든 데이터는 업계 표준 암호화 방식으로 안전하게 보호됩니다"
                        />
                        <SecurityCard
                            icon={<Lock />}
                            title="안전한 로그인"
                            description="Google OAuth2.0을 통한 안전한 로그인 시스템"
                        />
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="landing-footer">
                <div className="footer-content">
                    <div className="footer-section">
                        <h3>CrewCrew</h3>
                        <p>항공사 승무원을 위한 스케줄 관리 서비스</p>
                    </div>
                    <div className="footer-section">
                        <h3>중요 링크</h3>
                        <ul>
                            <li>
                                <a href="/terms" target="_blank" rel="noopener noreferrer">
                                    이용약관
                                </a>
                            </li>
                            <li>
                                <a href="/privacyPolicy" target="_blank" rel="noopener noreferrer">
                                    개인정보처리방침
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>고객지원</h3>
                        <ul>
                            <li>이메일: crewcrew.world@gmail.com</li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>© 2024 CrewCrew. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

const FeatureCard = ({ icon, title, description }) => (
    <div className="feature-card">
        <div className="icon">
            {React.cloneElement(icon, { size: 32 })}
        </div>
        <div className="content">
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    </div>
);

const OAuthCard = ({ icon, title, description }) => (
    <div className="oauth-card">
        <div className="icon">
            {React.cloneElement(icon, { size: 32 })}
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

const SecurityCard = ({ icon, title, description }) => (
    <div className="security-card">
        <div className="icon">
            {React.cloneElement(icon, { size: 32 })}
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

const FeatureShowcase = ({ image, title, description, align }) => (
    <motion.div 
        className={`feature-showcase ${align}`}
        initial={{ opacity: 0, x: align === 'left' ? -50 : 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
    >
        <div className="feature-showcase-content">
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
        <div className="feature-showcase-image">
            <img src={image} alt={title} />
        </div>
    </motion.div>
);

const GoogleIcon = () => (
    <svg className="google-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
        <path fill="none" d="M0 0h48v48H0z"/>
    </svg>
);

export default LandingPage;